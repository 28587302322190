import React,{useState, useEffect} from "react"
import { Container,Row,Col } from "react-bootstrap";
import PropertyBanner from "../../../images/propertydetails/propertybanner-mobile.jpg";
import propertybannerTable from "../../../images/propertydetails/propertybannerTable.jpg";
import propertybannerDesktop from "../../../images/propertydetails/propertybanner-desktop.jpg";

import PropertyVideo from "../../../images/propertydetails/vidoePoster.jpg";
import PlayVideo from "../../Play/PlayVideo";
import "./PropertyBenner.scss"
import { ImageModule } from "../../../modules/Image_Module";
import { youtube_parser } from "../../common/utils";
import FsLightbox from 'fslightbox-react';
import Slider from "react-slick"
import $ from "jquery"
import OfferTag from "../../Offertag/Offertag";
import Statustag from "../../StatusTag/StatusTag";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// styles

// markup

const PropertyBenner = ( props ) => {
  const [isPlay,setPlay] = useState(false);

  const [ indexed, setIndex ] = useState(1);
  
  // Banner image popup
  const [toggler, setToggler] = useState(false);
  // Banner image popup

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const afterChangeHandler = (d) => {
    setIndex(d+1)
  }
  // Slider

  const openLighboxMobile = (e, ind) => {
    e.preventDefault();
    setPhotoIndex(ind);
    setIsOpen(true);
  }

  // useEffect(() => {
  //   $(".floorplanClick").click(function() {
  //     $('html, body').animate({
  //       scrollTop: $("#floorplans").offset().top - 130
  //     }, 'smooth');
  //   })
  // })

  const scrollToPos = (div_id) =>{
    $('html, body').animate({
      scrollTop: $("#"+div_id).offset().top - 120
    }, 'smooth');
  }

  // Slider
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint:1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint:1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      
    ]
  };

  

  // ggfx
  let processedImages = JSON.stringify({});
  if (props.property_details_banner_data && props.property_details_banner_data.imagetransforms && props.property_details_banner_data.imagetransforms.images_Transforms) {
      processedImages = props.property_details_banner_data.imagetransforms.images_Transforms;
  }
  // ggfx

  var lightImages2 = props.property_details_banner_data.images && props.property_details_banner_data.images.map(img => img.url);

const facts_payment_terms = props?.property_details_banner_data?.facts?.payment_terms;
const payment_terms = facts_payment_terms ? facts_payment_terms : "";
const SearchType = "lettings";
const search_type = props?.property_details_banner_data?.search_type === SearchType;
var tagStatusVal = props?.property_details_banner_data?.status
//console.log("tagStatusVal",tagStatusVal)
if(tagStatusVal === "rented"){
  tagStatusVal = "Let"
}
  return (
    <React.Fragment>
      <div className="new-property-banner">
        <div className="col-img">
          { <Statustag tagStatus={tagStatusVal}/>}
          {search_type && payment_terms && <OfferTag text={payment_terms+" Rent"} tagStatusVal={tagStatusVal}/>}
          { props.property_details_banner_data &&
            <picture>
              <Slider {...settings}
                afterChange = {
                  v => afterChangeHandler(v)
                }
              >
                {
                  props.property_details_banner_data.images.map(sliderimg => {
                    const slider_image_url = sliderimg
                    return <>
                      {/* <ImageModule ImageSrc={slider_image_url} /> */}
                      {/* <img src={slider_image_url.srcUrl} alt=""/> */}
                      <ImageModule ImageSrc={slider_image_url} imagename={"property.images.detail"} article_id={props.property_details_banner_data.id} imagetransforms={props.property_details_banner_data.ggfx_results} ggfxNew={true}  />
                    </>
                  })
                }
              </Slider>
                {/* <source media="(min-width:992px)" srcSet={propertybannerDesktop}/>
                <source media="(min-width:768px)" srcSet={propertybannerTable}/>
                <img src={PropertyBanner} alt=""/> */}
            </picture>
          }
          {/* Banner image lightbox */}
          {/* <FsLightbox
            toggler={ toggler }
            sources= {
              props.property_details_banner_data.images.map(lightboximg => {
                const lightbox_image_url = lightboximg
                return <>
                  <ImageModule ImageSrc={lightbox_image_url} imagename={"property.images.detail"} article_id={props.property_details_banner_data.id} imagetransforms={processedImages}  />
                </>
              })
            }
          /> */}
          {isOpen && (
            <Lightbox
              mainSrc={lightImages2[photoIndex]}
              nextSrc={lightImages2[(photoIndex + 1) % lightImages2.length]}
              prevSrc={lightImages2[(photoIndex + lightImages2.length - 1) % lightImages2.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + lightImages2.length - 1) % lightImages2.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % lightImages2.length)
              }
            />
          )}

          {/* Banner image lightbox */}
          <div className="property-banner-content">
            <Container>
              <Row>
                  <Col>
                    <div className="property-banner-tag">

                      <span className="images-count" onClick={(e) => openLighboxMobile(e, 0)}><i className="icon-full-view"></i> {indexed}/{props.property_details_banner_data.images.length} Photos</span>
                      {/* { props.property_details_banner_data.virtual_tour.length > 0 ?
                        <span className="video">Video</span>
                      : '' } */}
                      { props.property_details_banner_data.floorplan && props.property_details_banner_data.floorplan.length > 0 ?
                      <span className="floorplanClick" onClick={() => scrollToPos('floorplans')}>Floorplan</span>
                      : '' }
                    </div>
                  </Col>
              </Row>
            </Container>
          </div>
        </div>
        
        {/* <div className="col-img d-none d-lg-block img-zoom">
          <ImageModule ImageSrc={props.property_details_banner_data.images[1]} imagename={"property.images.detail"} article_id={props.property_details_banner_data.id} imagetransforms={processedImages}  />
            <strong className="play-btn-icon" onClick = { e => {setPlay(true)}}>
              <i className="icon-play-btn"></i>
            </strong>
        </div>

        <PlayVideo
          isOpen = { isPlay } 
          isCloseFunction = { setPlay }
          videoId="luZRKtAUb2A"
          isAutoPlay={1}
        /> */}
      </div>
    </React.Fragment>
  )
}

export default PropertyBenner